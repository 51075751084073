import React from 'react'
import {ReactComponent as Book} from '../../../assets/images/book.svg'

import './Spinner.css'

const spinner = () => (
	<div className='Spinner'>
		<Book />
	</div>
)

export default spinner