import React, {Component} from 'react'
import Empty from '../Empty/Empty'
import './Layout.css'
import Toolbar from '../../components/Navigation/Toolbar/Toolbar'
import SideDrawer from '../../components/Navigation/SideDrawer/SideDrawer'
import Footer from '../../components/Navigation/Footer/Footer'
import ReactGA4 from 'react-ga4'

class Layout extends Component {
	state = {
		showSideDrawer: false
	}

	sideDrawerToggleHandler = () => {
		this.setState({showSideDrawer: !this.state.showSideDrawer})

		// Google Analytics
		ReactGA4.event({
			category: 'Button',
			action: 'Opened sidebar menu'
		})
	}

	sideDrawerClosedHandler = () => {
		this.setState({showSideDrawer: false})
	}

	render () {
		return (
			<Empty>
				<Toolbar sideDrawerIsOpen={this.state.showSideDrawer} sideDrawerToggle={this.sideDrawerToggleHandler}/>
				<SideDrawer open={this.state.showSideDrawer} closed={this.sideDrawerClosedHandler}/>
				<main className={'Main'}>
					{this.props.children}
				</main>
				<Footer />
			</Empty>
		)
	}
}

export default Layout