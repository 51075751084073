import React, {Component} from 'react'
import Container from '../../components/Container/Container'
import Form from  '../../components/Form/Form'
import Spinner from  '../../components/UI/Spinner/Spinner'
import Error from  '../../components/Error/Error'
import axios from '../../axios-instance'
import Modal from '../../components/UI/Modal/Modal';
import ReactGA4 from 'react-ga4'

class Contact extends Component {
	state = {
		copy: {
			headline: 'Kontakt',
			formLabels: {
				firstname: 'Vorname',
				lastname: 'Nachname',
				email: 'E-Mail Adresse',
				message: 'Nachricht'
			},
			button: 'Senden',
			formSent: 'Kontaktformular wurde geschickt!'
		},
		contactForm: {
			firstname: {
				htmlId: 'contactFirstname',
				elementType: 'input',
				elementConfig: {
					type: 'text',
					placeholder: ' '
				},
				value: '',
				required: true
			},
			lastname: {
				htmlId: 'contactLastname',
				elementType: 'input',
				elementConfig: {
					type: 'text',
					placeholder: ' '
				},
				value: '',
				required: true
			},
			email: {
				htmlId: 'contactEmail',
				elementType: 'input',
				elementConfig: {
					type: 'email',
					placeholder: ' '
				},
				value: '',
				required: true
			},
			message: {
				htmlId: 'contactMessage',
				elementType: 'textarea',
				value: '',
				required: true
			}
		},
		loading: true,
		formLoading: false,
		errorMessage: '',
		modalMessage: ''
	}

	componentDidMount = () => {
		// Google Analytics
		ReactGA4.initialize('G-0Y9M8BCZM7')

		this.setState({loading: false})
	}

	formHandler = (e) => {
		const formIsValid = e.currentTarget.checkValidity()
		e.preventDefault()

		const formData = new FormData()
		for (let formElementIdentifier in this.state.contactForm) {
			formData.append(formElementIdentifier, this.state.contactForm[formElementIdentifier].value)
		}

		if (formIsValid) {
			this.setState( { formLoading: true } )
			axios.post( '/contact', formData )
				.then( response => {
					this.setState({
						contactForm: {
							firstname: {
								value: ''
							},
							lastname: {
								value: ''
							},
							email: {
								value: ''
							},
							message: {
								value: ''
							}
						},
						formLoading: false,
						modalMessage: this.state.copy.formSent
					})
				})
				.catch( error => {
					this.setState({
						loading: false,
						errorMessage: error.message
					})
				})

			// Google Analytics
			ReactGA4.event({
				category: 'Form',
				action: 'Contact form submitted'
			})
		}
	}

	inputChangedHandler = (event, inputIdentifier) => {
		const updatedForm = {
			...this.state.contactForm
		}
		const updatedFormElement = {
			...updatedForm[inputIdentifier]
		}
		if (updatedFormElement.elementType === 'checkbox') {
			updatedFormElement.value = event.target.checked
		}
		else {
			updatedFormElement.value = event.target.value
		}
		updatedForm[inputIdentifier] = updatedFormElement
		this.setState({contactForm: updatedForm})
	}

	modalClosedHandler = () => {
		this.setState({modalMessage: ''})
	}

	render () {
		if (this.state.errorMessage) {
			return <Error errorMessage={this.state.errorMessage} />
		}

		if (this.state.loading) {
			return <Spinner />
		}

		const formElementsArray = []
		for (let key in this.state.contactForm) {
			formElementsArray.push({
				id: key,
				label: this.state.copy.formLabels[key],
				config: this.state.contactForm[key]
			})
		}
		return (
			<div className='Contact'>
				<Modal show={this.state.modalMessage ? true : false} modalClosed={this.modalClosedHandler}>
					<p>{this.state.modalMessage}</p>
				</Modal>
				<Container>
					<h1 className='text-center'>{this.state.copy.headline}</h1>
					<Form
					formElements={formElementsArray}
					formHandler={this.formHandler}
					inputChangedHandler={this.inputChangedHandler}
					loading={this.state.loading}
					btnLabel={this.state.copy.button} />
				</Container>
			</div>
		)
	}
}

export default Contact