import React, {Component} from 'react'
import Empty from '../../hoc/Empty/Empty'
import Stage from '../../components/Stage/Stage'
import StageImg from '../../assets/images/open-book.jpeg'
import StageMobileImg from '../../assets/images/open-book-mobile.jpg'
import Container from '../../components/Container/Container'
import Spinner from  '../../components/UI/Spinner/Spinner'
import Error from  '../../components/Error/Error'
import ReactGA4 from 'react-ga4'

class NotFound extends Component {
	state = {
		loading: true,
		errorMessage: ''
	}

	componentDidMount = () => {
		this.setState({loading: false})

		// Google Analytics
		ReactGA4.initialize('G-0Y9M8BCZM7')
	}

	render() {
		if (this.state.errorMessage) {
			return <Error errorMessage={this.state.errorMessage} />
		}

		if (this.state.loading) {
			return <Spinner />
		}

		return (
			<Empty>
				<Stage desktop={StageImg} mobile={StageMobileImg} alt="Verloren im Buch" />
				<Container>
					<h1 className='text-center'>Diese Seite ist leider nicht im Buch!</h1>
					<p className='text-center'>Du hast eine Seite gefunden, die es nicht gibt. <a href="/">Hier</a> geht es wieder zurück auf die Titelseite!</p>
				</Container>
			</Empty>
		)
	}
}

export default NotFound