import React, { Component } from 'react'
import Spinner from  '../../components/UI/Spinner/Spinner'
import Container from  '../../components/Container/Container'
import NewsletterForm from  '../../components/NewsletterForm/NewsletterForm'
import Error from  '../../components/Error/Error'
import ReactGA4 from 'react-ga4'
import {Helmet} from 'react-helmet'

class Newsletter extends Component {
	state = {
		loading: true,
		errorMessage: ''
	}

	componentDidMount = () => {
		// Google Analytics
		ReactGA4.initialize('G-0Y9M8BCZM7')

		this.setState({
			loading: false
		})
	}

	render () {
		if (this.state.errorMessage) {
			return <Error errorMessage={this.state.errorMessage} />
		}

		if (this.state.loading) {
			return <Spinner />
		}

		return (
			<div className='Newsletter'>
				<Helmet>
					<title>Newsletter von Michelle C. Paige | Nichts mehr verpassen!</title>
					<meta name="description" content="Jetzt beim Newsletter von Michelle C. Paige anmelden und nichts mehr verpassen! Neuerscheinungen, Vorab-Infos und vieles mehr." />
				</Helmet>
				<Container>
					<h1 className='text-center'>Newsletter</h1>
					<p>Mit der Anmeldung zu meinem Newsletter erhältst du Neuigkeiten rund um meine Bücher als Erstes. Darunter:</p>
					<ul>
						<li>
							Neuerscheinungen werden angekündigt
						</li>
						<li>
							Vorab-Informationen wie Cover-Reveals, Leseproben und News zu meinen Büchern bekommst du hier zuerst
						</li>
						<li>
							Erhalte Einblicke in meinen Schreib-Prozess
						</li>
					</ul>
					<p>Und? Klingt gut?</p>
					<p>Dann ab zur Anmeldung - ich brauche nur noch ein paar Infos von dir:</p>
					<NewsletterForm />
				</Container>
			</div>
		)
	}
}

export default Newsletter