import React from "react"
import "./Stage.css"

const Stage = (props) => (
	<div className={'Stage'}>
		<picture>
			<source media="(max-width: 767px)" srcSet={props.mobile} />
			<source media="(min-width: 768px)" srcSet={props.desktop} />
			<img src={props.desktop} alt={props.alt} />
		</picture>
	</div>
)

export default Stage