import React, { Component } from 'react';
import { Routes, Route } from 'react-router';
import Home from './containers/Home/Home'
import Contact from './containers/Contact/Contact'
import Imprint from './containers/Imprint/Imprint'
import PrivacyPolicy from './containers/PrivacyPolicy/PrivacyPolicy'
import Blog from './containers/Blog/Blog'
import NotFound from './containers/NotFound/NotFound'
import Login from './containers/Login/Login'
import Newsletter from './containers/Newsletter/Newsletter'
import DoubleOptIn from './containers/DoubleOptIn/DoubleOptIn'
import Unsubscribe from './containers/Unsubscribe/Unsubscribe'
import Books from './containers/Books/Books'
import CircleOfNight from './containers/Books/CircleOfNight/CircleOfNight'
import WhereHaveTheStarsGone from './containers/Books/WhereHaveTheStarsGone/WhereHaveTheStarsGone'
import TogetherWeShine from './containers/Books/TogetherWeShine/TogetherWeShine'
import ForeverYours from './containers/Books/ForeverYours/ForeverYours'
//import Admin from './containers/Admin/Admin'

class MyRoutes extends Component {
	render () {
		return (
			<Routes>
				<Route path='/login' element={<Login />} />
				<Route path='/contact' element={<Contact />} />
				<Route path='/imprint' element={<Imprint />} />
				<Route path='/privacy-policy' element={<PrivacyPolicy />} />
				<Route path='/blog' element={Blog} />
				<Route path='/newsletter' element={<Newsletter />} />
				<Route path='/doubleoptin' element={<DoubleOptIn />} />
				<Route path='/unsubscribe' element={<Unsubscribe />} />
				<Route path='/buecher' element={<Books />} />
				<Route path='/buecher/circle-of-night-die-hexen-von-hamburg-urban-fantasy-trilogie' element={<CircleOfNight />} />
				<Route path='/buecher/where-have-the-stars-gone' element={<WhereHaveTheStarsGone />} />
				<Route path='/buecher/together-we-shine' element={<TogetherWeShine />} />
				<Route path='/buecher/forever-yours-anthologie' element={<ForeverYours />} />
				{/* <Route path='/admin' component={<Admin />} /> */}
				<Route path='/' exact="true" element={<Home />} />
				<Route path='*' element={<NotFound />} />
			</Routes>
		)
	}
}

export default MyRoutes