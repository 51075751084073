import React from 'react'
import './NavigationItem.css'

const navigationItem = (props) => {
	let linkClasses = []
	if (props.active) {
		linkClasses = ['active']
	}

	return (
		<li className="NavigationItem">
			<a href={props.link} className={linkClasses} onClick={props.onClick}>
				{props.children}
				<svg viewBox="0 0 129 129">
					<path d="m40.4,121.3c-0.8,0.8-1.8,1.2-2.9,1.2s-2.1-0.4-2.9-1.2c-1.6-1.6-1.6-4.2 0-5.8l51-51-51-51c-1.6-1.6-1.6-4.2 0-5.8 1.6-1.6 4.2-1.6 5.8,0l53.9,53.9c1.6,1.6 1.6,4.2 0,5.8l-53.9,53.9z"/>
				</svg>
			</a>
		</li>
	)
}

export default navigationItem