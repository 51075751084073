import React, { Component } from 'react'
import Spinner from  '../../components/UI/Spinner/Spinner'
import Container from  '../../components/Container/Container'
import Error from  '../../components/Error/Error'
import axios from '../../axios-instance'
import ReactGA4 from 'react-ga4'

class DoubleOptIn extends Component {
	state = {
		copy: {
			headline: 'Newsletter Anmeldung',
			text: 'Geschafft! Jetzt darfst du spannende Updates von mir mit jedem Newsletter erwarten!',
		},
		loading: true,
		errorMessage: ''
	}

	componentDidMount = () => {
		// Google Analytics
		ReactGA4.initialize('G-0Y9M8BCZM7')

		const urlParams = new URLSearchParams(window.location.search);

		if (urlParams.get('email') === null || urlParams.get('doubleoptin') === null) { return; }

		const formData = new FormData()
		formData.append('email', urlParams.get('email'))
		formData.append('doubleoptin', urlParams.get('doubleoptin'))

		axios.post( '/newsletterusers', formData )
			.then( response => {
				this.setState({
					loading: false
				})
			})
			.catch( error => {
				this.setState({
					loading: false,
					errorMessage: error.message
				})
			})
	}

	render () {
		if (this.state.errorMessage) {
			return <Error errorMessage={this.state.errorMessage} />
		}

		if (this.state.loading) {
			return <Spinner />
		}

		return (
			<div className='DoubleOptIn'>
				<Container>
					<h1 className='text-center'>{this.state.copy.headline}</h1>
					<p className='text-center'>{this.state.copy.text}</p>
				</Container>
			</div>
		)
	}
}

export default DoubleOptIn