import React, { Component } from 'react'
import Spinner from  '../../../components/UI/Spinner/Spinner'
import Container from  '../../../components/Container/Container'
import Content from '../../../components/Content/Content'
import Shops from '../../../components/Shops/Shops'
import Error from  '../../../components/Error/Error'
import WHTSGImg from '../../../assets/images/whtsg-book.png'
import ReactGA4 from 'react-ga4'
import {Helmet} from 'react-helmet'

class WhereHaveTheStarsGone extends Component {
	state = {
		loading: true,
		errorMessage: ''
	}

	componentDidMount = () => {
		// Google Analytics
		ReactGA4.initialize('G-0Y9M8BCZM7')

		this.setState({
			loading: false
		})
	}

	render () {
		if (this.state.errorMessage) {
			return <Error errorMessage={this.state.errorMessage} />
		}

		if (this.state.loading) {
			return <Spinner />
		}

		return (
			<div className='WhereHaveTheStarsGone'>
				<Helmet>
					<title>Where have the Stars gone | Rockstar-Romance in Island</title>
					<meta name="description" content="Berührende Slowburn-Romance mit Rockstar und endlosen Sommertagen im traumhaften Island." />
				</Helmet>
				<Container>
					<h1 className='text-center'>Where have the Stars gone: Rockstar-Romance in Island</h1>
					<Content img={WHTSGImg} alt="Where have the Stars gone">
						<p><strong>Berührende Slowburn-Romance mit Rockstar und endlosen Sommertagen im traumhaften Island.</strong></p>
						<p>Nachdem die junge Musikerin Saga mit ihrem Duett-Partner Ingvi den wichtigsten Menschen ihres Lebens verloren hat, flieht sie für den Sommer in die wilden isländischen Highlands, um abzuschalten und alles hinter sich zu lassen. Dort trifft sie auf den amerikanischen Rockstar Tyler, der Inspiration für das neue Album seiner Band sucht. Die beiden haben einiges gemeinsam und zum ersten Mal seit Ingvis Tod hat Saga das Gefühl, wieder atmen zu können. Aber können die entstehenden Gefühle funktionieren? Ihre Zuflucht in den Bergen kann nicht von Dauer sein und Tyler geht bald mit seiner Band auf Welttournee …</p>
						<p><strong>»Tyler stimmt die nächste Melodie an, summt dabei ein paar Töne. Ich schließe die Augen, lege mich auf den Rücken und höre einfach nur zu. Irgendwann höre ich ihn die ersten Worte wispern.«</strong></p>
						<ul>
							<li>
								<em>
									Erscheinungstermin: <br />
									<ul>
										<li>Taschenbuch & eBook: 1.6.2023</li>
									</ul>
								</em>
							</li>
							<li>
								<em>
									Verlag: <br />
									<ul>
										<li><a href="https://www.piper.de/buecher/where-have-the-stars-gone-isbn-978-3-492-50673-1" target='_blank' rel='noopener noreferrer'>PIPER Digital</a></li>
									</ul>
								</em>
							</li>
							<li>
								<em>
									ISBN: <br />
									<ul>
										<li>eBook: 9783377900074</li>
										<li>Taschenbuch: 9783492506731</li>
									</ul>
								</em>
							</li>
						</ul>
						<Shops shops={[
							{
								label: 'Amazon',
								href: 'https://www.amazon.de/Where-have-Stars-gone-Burn-Romance/dp/3492506739',
								target: '_blank',
								GAInfos: 'Where have the Stars gone - Amazon'
							},
							{
								label: 'Thalia',
								href: 'https://www.thalia.de/shop/home/artikeldetails/A1067963076',
								target: '_blank',
								GAInfos: 'Where have the Stars gone - Thalia'
							},
							{
								label: 'PIPER',
								href: 'https://www.piper.de/buecher/where-have-the-stars-gone-isbn-978-3-492-50673-1',
								target: '_blank',
								GAInfos: 'Where have the Stars gone - PIPER Digital'
							},
							{
								label: 'Hugendubel',
								href: 'https://www.hugendubel.de/de/taschenbuch/michelle_c_paige-where_have_the_stars_gone-45607911-produkt-details.html',
								target: '_blank',
								GAInfos: 'Where have the Stars gone - Hugendubel'
							},
						]} onclick={this.shopClickHandler} />
					</Content>
				</Container>
			</div>
		)
	}
}

export default WhereHaveTheStarsGone