import React from 'react'
import './ContentBox.css'

const contentBox = (props) => (
	<div className={'ContentBox'}>
		{props.link ? (
			<a href={props.link}>
				{props.children}
			</a>
		) : (props.children)}
	</div>
)

export default contentBox