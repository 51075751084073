import React, {Component} from 'react'
import NavigationItem from './NavigationItem/NavigationItem'
import './NavigationItems.css'
import Spinner from  '../../../components/UI/Spinner/Spinner'
import Error from  '../../../components/Error/Error'

class NavigationItems extends Component {
	state = {
		links: [
			{
				label: 'Willkommen',
				link: '/#Home'
			},
			{
				label: 'Bücher',
				link: '/buecher'
			},
			{
				label: 'Über mich',
				link: '/#About'
			},
			{
				label: 'Newsletter',
				link: '/newsletter'
			},
			{
				label: 'Michelle\'s Blog',
				link: 'https://blog.michellecpaige.com/'
			}
		],
		loading: true,
		errorMessage: ''
	}

	componentDidMount = () => {
		this.setState({loading: false})
	}

	render() {
		if (this.state.errorMessage) {
			return <Error errorMessage={this.state.errorMessage} />
		}

		if (this.state.loading) {
			return (
				<div className='NavigationItems'>
					<Spinner />
				</div>
			)
		}

		return (
			<ul className={'NavigationItems'}>
				{this.state.links.map((navigationItem, index) => (
					<NavigationItem key={index} link={navigationItem.link} onClick={this.props.closeSidebar}>{navigationItem.label}</NavigationItem>
				))}
			</ul>
		)
	}
}

export default NavigationItems