import React, { Component } from 'react'
import Spinner from  '../../components/UI/Spinner/Spinner'
import Container from  '../../components/Container/Container'
import BookCard from '../../components/BookCard/BookCard'
import Button from '../../components/UI/Button/Button'
import Stage from '../../components/Stage/Stage'
import Error from  '../../components/Error/Error'
import StageDesktopImg from '../../assets/images/cotn-banner-desktop.png'
import StageMobileImg from '../../assets/images/cotn-banner-mobile.png'
import COTNImg from '../../assets/images/teaser-cotn.png'
import WHTSGImg from '../../assets/images/teaser-whtsg.png'
import TogetherWeShineImg from '../../assets/images/teaser-together-we-shine.png'
import ForeverYoursImg from '../../assets/images/teaser-forever-yours.png'
import ReactGA4 from 'react-ga4'
import {Helmet} from 'react-helmet'

class Books extends Component {
	state = {
		loading: true,
		errorMessage: ''
	}

	componentDidMount = () => {
		// Google Analytics
		ReactGA4.initialize('G-0Y9M8BCZM7')

		this.setState({
			loading: false
		})
	}

	render () {
		if (this.state.errorMessage) {
			return <Error errorMessage={this.state.errorMessage} />
		}

		if (this.state.loading) {
			return <Spinner />
		}

		return (
			<div className='Books'>
				<Helmet>
					<title>Bücher von Michelle C. Paige</title>
					<meta name="description" content="Alle Bücher von Michelle C. Paige." />
				</Helmet>
				<Stage desktop={StageDesktopImg} mobile={StageMobileImg} alt="Michelle's Bücher" />
				<Container>
					<h1 className='text-center'>Meine Bücher</h1>
					<div style={ {
						display: 'flex',
						flexWrap: 'wrap'
					} }>
						<BookCard link="/buecher/circle-of-night-die-hexen-von-hamburg-urban-fantasy-trilogie">
							<img src={COTNImg} alt="Circle of Night - Das Reich der Schatten" />
							<h2>Circle of Night</h2>
							<p>
								Die Hexen und Hexer von Hamburg …<br/>
								Die ganze Trilogie
							</p>
							<Button href="/buecher/circle-of-night-die-hexen-von-hamburg-urban-fantasy-trilogie" gainfos="Circle of Night - Das Reich der Schatten - Books">Zur Reihe</Button>
						</BookCard>
						<BookCard link="/buecher/where-have-the-stars-gone">
							<img src={WHTSGImg} alt="Where have the Stars gone" />
							<h2>Where have the Stars gone</h2>
							<p>Berührende Slowburn-Romance mit Rockstar und endlosen Sommertagen im traumhaften Island.</p>
							<Button href="/buecher/where-have-the-stars-gone" gainfos="Where have the Stars gone - Books">Zum Buch</Button>
						</BookCard>
						<BookCard link="/buecher/together-we-shine">
							<img src={TogetherWeShineImg} alt="Together we shine" />
							<h2>Together we shine</h2>
							<p>
								Ein gebrochenes Herz und<br/>
								die Magie der Polarlichter
							</p>
							<Button href="/buecher/together-we-shine" gainfos="Together we shine - Books">Zum Buch</Button>
						</BookCard>
						<BookCard link="/buecher/forever-yours-anthologie">
							<img src={ForeverYoursImg} alt="Forever Yours: 10 Geschichten über die Liebe" />
							<h2>Forever Yours</h2>
							<p>10 Geschichten über die Liebe <br />10x Herzklopfen. 10x Drama. 10x Liebe.</p>
							<Button href="/buecher/forever-yours-anthologie" gainfos="Forever Yours - Books">Zur Anthologie</Button>
						</BookCard>
					</div>
				</Container>
			</div>
		)
	}
}

export default Books