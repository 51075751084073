import React from 'react'
import './Form.css'
import Button from  '../../components/UI/Button/Button'
import Input from '../../components/UI/Input/Input'
import Spinner from '../../components/UI/Spinner/Spinner'

const form = (props) => {
	if ( props.loading ) {
		return <Spinner />
	}

	return (
		<div className={'Form' + (props.cols ? ' cols-' + props.cols : '')}>
			<form onSubmit={props.formHandler}>
				{props.formElements.map(formElement => (
					<Input
						key={formElement.config.htmlId}
						id={formElement.config.htmlId}
						elementType={formElement.config.elementType}
						elementConfig={formElement.config.elementConfig}
						label={formElement.label}
						value={formElement.config.value}
						required={formElement.config.required}
						changed={(event) => props.inputChangedHandler(event, formElement.id)} />
				))}
				<Button btnType='center'>{props.btnLabel}</Button>
			</form>
		</div>
	)

}

export default form