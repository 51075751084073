import React, {Component} from 'react'
import NavigationItem from '../NavigationItems/NavigationItem/NavigationItem'
import './Footer.css'
import Spinner from  '../../../components/UI/Spinner/Spinner'
import Error from  '../../../components/Error/Error'
import CookieConsent from '../../../components/CookieConsent/CookieConsent'
//import axios from '../../../axios-instance'

class Footer extends Component {
	state = {
		links: [
			{
				label: 'Kontakt',
				link: '/contact'
			},
			{
				label: 'Datenschutz',
				link: '/privacy-policy'
			},
			{
				label: 'Impressum',
				link: '/imprint'
			}
		],
		loading: true,
		errorMessage: ''
	}

	componentDidMount = () => {
		this.setState({loading: false})
	}

	render() {
		if (this.state.errorMessage) {
			return <Error errorMessage={this.state.errorMessage} />
		}

		if (this.state.loading) {
			return (
				<div className='Footer'>
					<Spinner />
				</div>
			)
		}

		return (
			<footer className={'Footer'}>
				<CookieConsent />
				<span className="copyright">© {new Date().getFullYear()} Michelle C. Paige</span>
				<ul>
					{this.state.links.map((navigationItem, index) => (
						<NavigationItem key={index} link={navigationItem.link} active={window.location.pathname === navigationItem.link}>{navigationItem.label}</NavigationItem>
					))}
				</ul>
			</footer>
		)
	}
}

export default Footer