import React, { Component } from 'react'
import Spinner from  '../../../components/UI/Spinner/Spinner'
import Container from  '../../../components/Container/Container'
import Content from '../../../components/Content/Content'
import Shops from '../../../components/Shops/Shops'
import Error from  '../../../components/Error/Error'
import ForeverYoursImg from '../../../assets/images/forever-yours-book.png'
import ReactGA4 from 'react-ga4'
import {Helmet} from 'react-helmet'

class ForeverYours extends Component {
	state = {
		loading: true,
		errorMessage: ''
	}

	componentDidMount = () => {
		// Google Analytics
		ReactGA4.initialize('G-0Y9M8BCZM7')

		this.setState({
			loading: false
		})
	}

	render () {
		if (this.state.errorMessage) {
			return <Error errorMessage={this.state.errorMessage} />
		}

		if (this.state.loading) {
			return <Spinner />
		}

		return (
			<div className='ForeverYours'>
				<Helmet>
					<title>Forever Yours | 10 Geschichten über die Liebe</title>
					<meta name="description" content="In zehn Kurzgeschichten zeigen Forever-Autorinnen die Vielfalt des schönsten aller Gefühle. Dabei tauchen sie erneut in die Welten ihrer Romane ein und beweisen: Love wins." />
				</Helmet>
				<Container>
					<h1 className='text-center'>Forever Yours: 10 Geschichten über die Liebe</h1>
					<Content img={ForeverYoursImg} alt="Forever Yours: 10 Geschichten über die Liebe">
						<p><strong>10x Herzklopfen. 10x Drama. 10x Liebe.</strong></p>
						<p>
							Die Liebe hat viele Gesichter! In zehn Kurzgeschichten zeigen Forever-Autorinnen die Vielfalt des schönsten aller Gefühle. Dabei tauchen sie erneut in die Welten ihrer Romane ein und beweisen: Love wins. Always and Forever!<br />
							Romantische, dramatische und emotionale Storys erzählt von: Juliette Bensch, Ally Crowe, Nadine Dela, Jennifer Edmond, Julica Fernholz, Nicole Klein, Vanessa Kolwitz, Murphy Malone, Katharina Olbert und Michelle C. Paige.
						</p>
						<p>Die Kurzgeschichten können unabhängig von den Romanen der Autorinnen gelesen werden.</p>
						<p><strong>Kurzgeschichte von Michelle C. Paige: »A Little Bit Forever« - Die Vorgeschichte von Emilia und Noah aus »Together we shine«</strong></p>
						<ul>
							<li><em>Format: eBook</em></li>
							<li><em>Erscheinungstermin: 2. Januar 2023</em></li>
							<li><em>Verlag: <a href="https://forever.ullstein.de/books/" target='_blank' rel='noopener noreferrer'>Forever by Ullstein</a></em></li>
							<li><em>ISBN: 9783958187283</em></li>
						</ul>
						<Shops shops={[
							{
								label: 'Amazon',
								href: 'https://www.amazon.de/gp/product/B0BQ7S7P7X/',
								target: '_blank',
								GAInfos: 'Forever Yours: 10 Geschichten über die Liebe - Amazon'
							},
							{
								label: 'Thalia',
								href: 'https://www.thalia.de/shop/home/artikeldetails/A1067410697',
								target: '_blank',
								GAInfos: 'Forever Yours: 10 Geschichten über die Liebe - Thalia'
							},
							{
								label: 'Forever',
								href: 'https://forever.ullstein.de/werke/forever-yours/epub/9783958187283',
								target: '_blank',
								GAInfos: 'Forever Yours: 10 Geschichten über die Liebe - Forever'
							},
							{
								label: 'Hugendubel',
								href: 'https://www.hugendubel.de/de/ebook_epub/jennifer_edmond_juliette_bensch_nicole_klein_vanessa_kolwitz_katharina_olbert-forever_yours-45231602-produkt-details.html',
								target: '_blank',
								GAInfos: 'Forever Yours: 10 Geschichten über die Liebe - Hugendubel'
							}
						]} onclick={this.shopClickHandler} />
					</Content>
				</Container>
			</div>
		)
	}
}

export default ForeverYours