import React from 'react'
import ContentBox from './ContentBox/ContentBox'
import './Content.css'

const Content = (props) => {
	let attachedClasses = ['Content']
	if (props.imgRight) {
		attachedClasses = ['Content', 'Content--img_right']
	}
	return (
		<div className={attachedClasses.join(' ')}>
			<ContentBox link={props.link}><img src={props.img} alt={props.alt} /></ContentBox>
			<ContentBox>{props.children}</ContentBox>
		</div>
	)
}

export default Content