import React from 'react'
import './BlogEntry.css'

const blogEntry = (props) => {
	const date = new Date(parseInt(props.timestamp, 10))
	return (
		<div className='BlogEntry'>
			<p className='EntryDate'><strong>{date.toDateString()}</strong></p>
			{props.markup}
		</div>
	)
}

export default blogEntry