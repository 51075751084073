import React, {Component} from 'react'
import Container from '../../components/Container/Container'
import Spinner from  '../../components/UI/Spinner/Spinner'
import Error from  '../../components/Error/Error'
import Empty from  '../../hoc/Empty/Empty'
import BlogEntries from  '../../components/BlogEntries/BlogEntries'
import axios from '../../axios-instance'
import Modal from '../../components/UI/Modal/Modal';
import Button from '../../components/UI/Button/Button';
import ReactGA4 from 'react-ga4'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

class Blog extends Component {
	state = {
		copy: {
			headline: `Michelle's Blog`,
			formLabels: {
				message: 'Neuer Blog Eintrag'
			},
			button: 'Speichern',
			formSent: 'Blogformular wurde geschickt!',
			formEmpty: 'Blogformular ist leer!'
		},
		rte: '',
		loading: true,
		formLoading: false,
		errorMessage: '',
		modalMessage: '',
		blogEntries: [],
		authorized: false
	}

	componentDidMount = () => {
		// Google Analytics
		ReactGA4.initialize('G-0Y9M8BCZM7')

		this.getBlogEntries()
		this.checkAuthentication()
	}

	getBlogEntries = () => {
		this.setState({loading: true})

		axios.get( '/blog' )
			.then( response => {
				this.setState({
					loading: false,
					blogEntries: response.data
				})
			})
			.catch( error => {
				this.setState({
					loading: false,
					errorMessage: error.message
				})
			})
	}

	checkAuthentication = () => {
		axios.post( '/private' )
			.then( response => {
				this.setState({
					loading: false,
					authorized: true
				})
			})
			.catch( error => {
				this.setState({
					loading: false,
					authorized: false
				})
			})
	}

	onRteChange = (value) => {
		this.setState({
			rte: value
		})
	}

	saveBlogEntry = () => {
		if (this.state.rte === '') {
			this.setState({
				modalMessage: this.state.copy.formEmpty
			})
			return
		}

		const formData = new FormData()

		formData.append('timestamp', Date.now())
		formData.append('content', this.state.rte.replace(/(\r\n|\n|\r)/gm, ""))


		this.setState( { formLoading: true } )
		axios.post( '/blog', formData )
			.then( response => {
				this.getBlogEntries()
				this.setState({
					BlogForm: {
						content: {
							value: ''
						}
					},
					formLoading: false,
					modalMessage: this.state.copy.formSent
				})
			})
			.catch( error => {
				this.setState({
					loading: false,
					errorMessage: error.message
				})
			})

		// Google Analytics
		ReactGA4.event({
			category: 'Form',
			action: 'Blog form submitted'
		})
	}

	modalClosedHandler = () => {
		this.setState({modalMessage: ''})
	}

	quillModules = {
		toolbar: [
		  [{ 'header': [1, 2, false] }],
		  ['bold', 'italic', 'underline','strike', 'blockquote'],
		  [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
		  ['link', 'image'],
		  ['clean']
		],
	}

	quillFormats = [
		'header',
		'bold', 'italic', 'underline', 'strike', 'blockquote',
		'list', 'bullet', 'indent',
		'link', 'image'
	]

	render () {
		if (this.state.errorMessage) {
			return <Error errorMessage={this.state.errorMessage} />
		}

		if (this.state.loading) {
			return <Spinner />
		}

		const formElementsArray = []
		for (let key in this.state.blogForm) {
			formElementsArray.push({
				id: key,
				label: this.state.copy.formLabels[key],
				config: this.state.blogForm[key]
			})
		}
		return (
			<div className='Blog'>
				<Modal show={this.state.modalMessage ? true : false} modalClosed={this.modalClosedHandler}>
					<p>{this.state.modalMessage}</p>
				</Modal>
				<Container>
					<h1 className='text-center'>{this.state.copy.headline}</h1>
					<BlogEntries entries={this.state.blogEntries} />
					{this.state.authorized ?
						<Empty>
							<ReactQuill value={this.state.rte} onChange={this.onRteChange} modules={this.quillModules} formats={this.quillFormats} />
							<Button onclick={this.saveBlogEntry}>{this.state.copy.button}</Button>
						</Empty> : null
					}
				</Container>
			</div>
		)
	}
}

export default Blog