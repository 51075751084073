import React from 'react'
import { NavLink } from 'react-router-dom'
import './Logo.css'

const logo = (props) => (
	<div className={'Logo'}>
		<NavLink to='/' exact="true">
			<img src="/logo.png" alt="Michelle C. Paige" />
		</NavLink>
	</div>
)

export default logo