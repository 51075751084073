import React, { Component } from 'react'
import './NewsletterForm.css'
import Spinner from  '../../components/UI/Spinner/Spinner'
import Form from  '../../components/Form/Form'
import Container from  '../../components/Container/Container'
import Error from  '../../components/Error/Error'
import axios from '../../axios-instance'
import Modal from '../../components/UI/Modal/Modal'
import { ReCaptcha } from 'react-recaptcha-v3'
import ReactGA4 from 'react-ga4'

class NewsletterForm extends Component {
	state = {
		copy: {
			formLabels: {
				name: 'Vorname',
				email: 'E-Mail Adresse',
				optin: 'Ich stimme der <a href="/privacy-policy">Datenschutzerklärung</a> zu.'
			},
			button: 'Anmelden',
			formSent: 'Fast geschafft - bitte bestätige deine Anmeldung in der E-Mail, die du bekommen haben solltest (bitte auch Spam-Ordner prüfen)'
		},
		newsletterForm: {
			name: {
				htmlId: 'newsletterName',
				elementType: 'input',
				elementConfig: {
					type: 'text',
					placeholder: ' '
				},
				value: '',
				required: true
			},
			email: {
				htmlId: 'newsletterEmail',
				elementType: 'input',
				elementConfig: {
					type: 'email',
					placeholder: ' '
				},
				value: '',
				required: true
			},
			optin: {
				htmlId: 'newsletterOptin',
				elementType: 'checkbox',
				elementConfig: {
					type: 'checkbox',
					placeholder: ' '
				},
				value: '',
				required: true
			}
		},
		loading: true,
		formLoading: false,
		errorMessage: '',
		modalMessage: ''
	}

	componentDidMount = () => {
		this.setState({loading: false})
	}

	formHandler = (e) => {
		const formIsValid = e.currentTarget.checkValidity()
		e.preventDefault()

		const formData = new FormData()
		for (let formElementIdentifier in this.state.newsletterForm) {
			formData.append(formElementIdentifier, this.state.newsletterForm[formElementIdentifier].value)
		}

		if (formIsValid) {
			this.setState( { formLoading: true } )

			axios.post( '/newsletterusers', formData )
				.then( response => {
					this.setState({
						formLoading: false,
						modalMessage: this.state.copy.formSent
					})
				})
				.catch( error => {
					this.setState({
						loading: false,
						errorMessage: error.message
					})
				})

			// Google Analytics
			ReactGA4.event({
				category: 'Form',
				action: 'Newsletter form submitted'
			})
		}
	}

	inputChangedHandler = (event, inputIdentifier) => {
		const updatedForm = {
			...this.state.newsletterForm
		}
		const updatedFormElement = {
			...updatedForm[inputIdentifier]
		}
		if (updatedFormElement.elementType === 'checkbox') {
			updatedFormElement.value = event.target.checked
		}
		else {
			updatedFormElement.value = event.target.value
		}
		updatedForm[inputIdentifier] = updatedFormElement
		this.setState({newsletterForm: updatedForm})
	}

	modalClosedHandler = () => {
		this.setState({modalMessage: ''})
	}

	verifyCallback = (recaptchaToken) => {
		const recaptchaBody = new FormData()

		recaptchaBody.append('response', recaptchaToken)

		axios.post( '/google', recaptchaBody )
			.then( response => {
				if (!response.data.success) {
					this.setState({
						loading: false,
						errorMessage: 'You\'re a robot!'
					})
				}
			})
			.catch( error => {
				this.setState({
					loading: false,
					errorMessage: error.message
				})
			})
	}

	render () {
		if (this.state.errorMessage) {
			return <Error errorMessage={this.state.errorMessage} />
		}

		if (this.state.loading) {
			return <Spinner />
		}
		const formElementsArray = []
		for (let key in this.state.newsletterForm) {
			formElementsArray.push({
				id: key,
				label: this.state.copy.formLabels[key],
				config: this.state.newsletterForm[key]
			})
		}
		return (
			<div className='NewsletterForm'>
				<Container>
					<Form
						formElements={formElementsArray}
						formHandler={this.formHandler}
						inputChangedHandler={this.inputChangedHandler}
						loading={this.state.formLoading}
						btnLabel={this.state.copy.button} />
					{<ReCaptcha
						sitekey="6Lf7c70UAAAAAMk2dDV_VOQ36ZqSMVwLAyiCmL2L"
						action='newsletter'
						verifyCallback={this.verifyCallback}
					/>}
				</Container>
				<Modal show={this.state.modalMessage ? true : false} modalClosed={this.modalClosedHandler}>
					<p>{this.state.modalMessage}</p>
				</Modal>
			</div>
		)
	}
}

export default NewsletterForm