import React from 'react'
import './Button.css'

const button = (props) => {
	const attachedClasses = ['Button', props.btnType]
	if (props.active) {
		attachedClasses.push('active')
	}

	if (props.href) {
		return (
			<a
				className={attachedClasses.join(' ')}
				onClick={props.onclick}
				href={props.href}
				target={props.target}
				rel='noopener noreferrer'
				data-gainfos={props.gainfos}
			>{props.children}</a>
		)
	}

	return (
		<button
			className={attachedClasses.join(' ')}
			onClick={props.onclick}
			href={props.href}
		>{props.children}</button>
	)
}

export default button