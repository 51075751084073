import React from 'react'
import BlogEntry from  '../BlogEntry/BlogEntry'
import parse from 'html-react-parser'

const blogEntries = (props) => {
	if (props.entries === '0 results') {
		return null
	}

	return (
		<div className='BlogEntries'>
			{props.entries.map((entry, index) => (
				<BlogEntry key={index} timestamp={entry.timestamp} markup={parse(entry.content)} />
			))}
		</div>
	)
}

export default blogEntries