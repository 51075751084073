import React from 'react'
import './Toolbar.css'
import Logo from '../../Logo/Logo'
import NavigationItems from '../../Navigation/NavigationItems/NavigationItems'
import SocialIcons from '../../Navigation/SocialIcons/SocialIcons'

const toolbar = (props) => (
	<header className={'Toolbar'}>
		<div className={'UpperHeader'}>
			<button
				className={`DrawerToggle ${props.sideDrawerIsOpen && 'active'}`}
				onClick={props.sideDrawerToggle}>
				<div></div>
				<div></div>
				<div></div>
			</button>
			<div className={'LogoWrapper'}>
				<Logo/>
			</div>
			<div className={'DesktopOnly'}>
				<SocialIcons />
			</div>
		</div>
		<div className={'LowerHeader'}>
			<nav className={'DesktopOnly'}>
				<NavigationItems/>
			</nav>
		</div>
	</header>
)

export default toolbar
