import React, {Component} from 'react'
import Empty from '../../hoc/Empty/Empty'
import Spinner from  '../../components/UI/Spinner/Spinner'
import Error from  '../../components/Error/Error'
import Container from '../../components/Container/Container'
import Form from  '../../components/Form/Form'
import Modal from '../../components/UI/Modal/Modal';
import axios from '../../axios-instance'
import ReactGA4 from 'react-ga4'

class Login extends Component {
	state = {
		copy: {
			headline: `Login`,
			formLabels: {
				username: 'Username',
				password: 'Passwort'
			},
			button: 'Speichern',
			formSent: 'formular wurde geschickt!'
		},
		form: {
			username: {
				htmlId: 'loginUsername',
				elementType: 'input',
				elementConfig: {
					type: 'text',
					placeholder: ' '
				},
				value: '',
				required: true
			},
			password: {
				htmlId: 'loginPassword',
				elementType: 'input',
				elementConfig: {
					type: 'password',
					placeholder: ' '
				},
				value: '',
				required: true
			}
		},
		loading: true,
		formLoading: false,
		errorMessage: '',
		modalMessage: ''
	}

	componentDidMount = () => {
		this.setState({loading: false})
	}

	formHandler = (e) => {
		const formIsValid = e.currentTarget.checkValidity()
		e.preventDefault()

		const formData = new FormData()
		for (let formElementIdentifier in this.state.form) {
			formData.append(formElementIdentifier, this.state.form[formElementIdentifier].value)
		}

		if (formIsValid) {
			this.setState( { loading: true } )

			axios.post( '/login', formData )
				.then( response => {
					this.setState({
						loading: false
					})
					window.location = '/blog'
				})
				.catch( error => {
					this.setState({
						loading: false,
						errorMessage: error.message
					})
				})
		}
	}

	inputChangedHandler = (event, inputIdentifier) => {
		const updatedForm = {
			...this.state.form
		}
		const updatedFormElement = {
			...updatedForm[inputIdentifier]
		}
		if (updatedFormElement.elementType === 'checkbox') {
			updatedFormElement.value = event.target.checked
		}
		else {
			updatedFormElement.value = event.target.value
		}
		updatedForm[inputIdentifier] = updatedFormElement

		this.setState({form: updatedForm})
	}

	render() {
		if (this.state.errorMessage) {
			return <Error errorMessage={this.state.errorMessage} />
		}

		if (this.state.loading) {
			return <Spinner />
		}

		const formElementsArray = []
		for (let key in this.state.form) {
			formElementsArray.push({
				id: key,
				label: this.state.copy.formLabels[key],
				config: this.state.form[key]
			})
		}

		return (
			<Empty>
				<Modal show={this.state.modalMessage ? true : false} modalClosed={this.modalClosedHandler}>
					<p>{this.state.modalMessage}</p>
				</Modal>
				<Container>
					<h1 className='text-center'>{this.state.copy.headline}</h1>

					<Form
					formElements={formElementsArray}
					formHandler={this.formHandler}
					inputChangedHandler={this.inputChangedHandler}
					loading={this.state.loading}
					btnLabel={this.state.copy.button} />
				</Container>
			</Empty>
		)
	}
}

export default Login