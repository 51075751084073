import React from 'react'
import './Alert.css'

const alert = (props) => (
	<a className='Alert' href={props.href}>
		<img src={props.src} alt={props.alt} />
	</a>
)

export default alert
