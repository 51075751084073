import React from 'react'
import './BookCard.css'

const bookCard = (props) => (
	<div className={`BookCard ${props.className}`}>
		{props.link ? (
			<a href={props.link}>
				{props.children}
			</a>
		) : (props.children)}
	</div>
)

export default bookCard