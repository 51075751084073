import React from 'react'
import './Error.css'

const error = (props) => (
	<div className={'Error'}>
		<div className='erroricon'></div>
		<div className='errortext'>
			<p className='text-center'>{props.errorMessage}</p>
		</div>
	</div>
)

export default error