import React, { Component } from 'react'
import Spinner from  '../../../components/UI/Spinner/Spinner'
import Container from  '../../../components/Container/Container'
import Content from '../../../components/Content/Content'
import Shops from '../../../components/Shops/Shops'
import Error from  '../../../components/Error/Error'
import TogetherWeShineImg from '../../../assets/images/together-we-shine-book.png'
import ReactGA4 from 'react-ga4'
import {Helmet} from 'react-helmet'

class TogetherWeShine extends Component {
	state = {
		loading: true,
		errorMessage: ''
	}

	componentDidMount = () => {
		// Google Analytics
		ReactGA4.initialize('G-0Y9M8BCZM7')

		this.setState({
			loading: false
		})
	}

	render () {
		if (this.state.errorMessage) {
			return <Error errorMessage={this.state.errorMessage} />
		}

		if (this.state.loading) {
			return <Spinner />
		}

		return (
			<div className='TogetherWeShine'>
				<Helmet>
					<title>Together we shine | Dramatischer Island Roman</title>
					<meta name="description" content="Ein gebrochenes Herz und die Magie der Polarlichter. Ein winterlicher und hoffnungsvoller Island-Roman über Trauer und Selbstfindung." />
				</Helmet>
				<Container>
					<h1 className='text-center'>Together we shine: Island Roman</h1>
					<Content img={TogetherWeShineImg} alt="Together we shine">
						<p><strong>Ein gebrochenes Herz und die Magie der Polarlichter</strong></p>
						<p>Den schwarzen Sand unter den Füßen und die tosenden Wellen vor sich – Emilia steht an der eisigen Küste Islands und erhofft sich nichts mehr vom Leben. Der plötzliche Verlust ihres Ehemanns hat eine tiefe Wunde in ihr hinterlassen. Eigentlich wollten sie zusammen die Nordlichter bestaunen, doch jetzt ist Emilia allein hier und es gibt keine Spur von den tanzenden Lichtern am nachtschwarzen Himmel. Emilia droht in ihrer Trauer zu versinken, bis Arnar in ihrem Leben auftaucht. Der sehr hilfsbereite Isländer zeigt ihr nicht nur die Schönheit der Insel, sondern könnte auch ihr Licht in der Dunkelheit sein. Doch ist Emilia überhaupt bereit, ihr Herz neu zu verschenken, obwohl es immer noch in Scherben liegt?</p>
						<p><strong>»Unser Herz hält uns am Leben, so viele Jahre ohne Pause und doch ist es extrem zerbrechlich. Es ergibt keinen Sinn, dass meines noch funktioniert, seitdem es in tausend Teile zersprungen ist.«</strong></p>
						<p><strong>Exklusiv im Taschenbuch: (Liebes)briefe von Noah</strong></p>
						<ul>
							<li><em>Format: eBook & Taschenbuch</em></li>
							<li>
								<em>
									Erscheinungstermin: <br />
									<ul>
										<li>eBook: 3.1.2022</li>
										<li>Taschenbuch: 1.12.2022</li>
									</ul>
								</em>
							</li>
							<li>
								<em>
									Verlag: <br />
									<ul>
										<li>eBook: <a href="https://forever.ullstein.de/books/" target='_blank' rel='noopener noreferrer'>Forever by Ullstein</a></li>
										<li>Taschenbuch: Selfpublishing</li>
									</ul>
								</em>
							</li>
							<li>
								<em>
									ISBN: <br />
									<ul>
										<li>eBook: 9783958186590</li>
										<li>Taschenbuch: 9783756857364</li>
									</ul>
								</em>
							</li>
						</ul>
						<Shops shops={[
							{
								label: 'Amazon',
								href: 'https://www.amazon.de/Together-shine-Magische-Nordlichter-ber%C3%BChrende-ebook/dp/B09NHW3X5F',
								target: '_blank',
								GAInfos: 'Together we shine - Amazon'
							},
							{
								label: 'Thalia',
								href: 'https://www.thalia.de/shop/home/artikeldetails/A1067180233',
								target: '_blank',
								GAInfos: 'Together we shine - Thalia'
							},
							{
								label: 'Books on Demand',
								href: 'https://www.bod.de/buchshop/together-we-shine-michelle-c-paige-9783756857364',
								target: '_blank',
								GAInfos: 'Together we shine - Books on Demand'
							},
							{
								label: 'Hugendubel',
								href: 'https://www.hugendubel.de/de/taschenbuch/michelle_c_paige-together_we_shine-45030948-produkt-details.html',
								target: '_blank',
								GAInfos: 'Together we shine - Hugendubel'
							},
							{
								label: 'Ullstein',
								href: 'https://www.ullstein-buchverlage.de/nc/buch/details/together-we-shine-9783958186590.html',
								target: '_blank',
								GAInfos: 'Together we shine - Ullstein'
							},
						]} onclick={this.shopClickHandler} />
					</Content>
				</Container>
			</div>
		)
	}
}

export default TogetherWeShine