import React, {Component} from 'react'
import './Modal.css'
import Backdrop from '../Backdrop/Backdrop'

class Modal extends Component {
	shouldComponentUpdate(nextProps) {
		return nextProps.show !== this.props.show
	}

	render() {
		let classes = 'Modal'

		if (this.props.show) {
			document.querySelector('body').style.overflow = 'hidden'
			classes = 'Modal active'
		} else {
			document.querySelector('body').style.overflow = 'auto'
			classes = 'Modal active out'
		}

		return (
			<div className={classes}>
				<Backdrop show={this.props.show} clicked={this.props.modalClosed} />
				<div className="content">
					<button className="modal-close" onClick={this.props.modalClosed} />
					{this.props.children}
				</div>
			</div>
		)
	}
}

export default Modal