import React, { Component } from 'react'
import { loadReCaptcha } from 'react-recaptcha-v3'
import Layout from './hoc/Layout/Layout'
import Routes from './Routes'
import ReactGA4 from 'react-ga4'

class App extends Component {

	componentDidMount() {
		// Google Analytics
		ReactGA4.initialize('G-0Y9M8BCZM7')

		// Google Recaptcha
		loadReCaptcha('6Lf7c70UAAAAAMk2dDV_VOQ36ZqSMVwLAyiCmL2L')
	}

	render() {
		return (
			<div>
				<Layout>
					<Routes/>
				</Layout>
			</div>
		)
	}
}

export default App
