import {React, useState} from 'react'
import './Shops.css'
import Button from '../../components/UI/Button/Button'
import Modal from '../../components/UI/Modal/Modal'

const Shops = (props) => {
	const [nextItemsActive, setNextItemsActive] = useState(false);

	const handleShowMoreClick = () => {
		document.activeElement.blur();
		setNextItemsActive(nextItemsActive => !nextItemsActive);
	}

	const handleModalClose = () => {
		setNextItemsActive(false);
	}

	const firstItems = [props.shops[0], props.shops[1]];
	const nextItems = props.shops.length > 2 ? props.shops.slice(2) : [];

	return (
		<div className={'Shops'}>
			<div className="first-items">
				{firstItems.map((shop, index) => <Button key={index} href={shop.href} gainfos={shop.GAInfos} onclick={props.onclick} target={shop.target}>{shop.label}</Button>)}
				{nextItems.length > 0 ? (<Button gainfos="Shops - Show more button" onclick={handleShowMoreClick}>...</Button>) : null}
			</div>
			<Modal show={nextItemsActive} modalClosed={handleModalClose}>
				<p>Weitere Shops</p>
				<div className="next-items">
					{nextItems.map((shop, index) => <Button key={index} href={shop.href} gainfos={shop.GAInfos} onclick={props.onclick} target={shop.target}>{shop.label}</Button>)}
				</div>
			</Modal>
		</div>
	)
}

export default Shops