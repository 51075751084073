import React from 'react'
import './Backdrop.css'

const backdrop = (props) => {
	if (props.show) {
		return (
			<div className="Backdrop" onClick={props.clicked}>{props.children}</div>
		)
	}

	return null
}

export default backdrop