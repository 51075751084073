import React, {Component} from 'react'
import './SocialIcons.css'
import Spinner from  '../../../components/UI/Spinner/Spinner'
import Error from  '../../../components/Error/Error'
//import axios from '../../../axios-instance'
import ReactGA4 from 'react-ga4'

class SocialIcons extends Component {
	state = {
		copy: {
			facebook: 'https://www.facebook.com/michellecpaige',
			instagram: 'https://www.instagram.com/michellecpaige_autorin/',
			tiktok: 'https://www.tiktok.com/@michellecpaige?lang=de-DE'
		},
		loading: true,
		// errorMessage: 'This is a test error message!'
	}

	componentDidMount = () => {
		this.setState({loading: false})
/* 		axios.get( '/pages/social' )
		.then( response => {
			this.setState({
				copy: JSON.parse(response.data.content),
				loading: false
			})
		})
		.catch( error => {
			this.setState({
				loading: false,
				errorMessage: error.message
			})
		}) */
	}

	socialClickHandler = (e) => {
		// Google Analytics
		ReactGA4.event({
			category: 'Button',
			action: 'Clicked social icon',
			label: e.target.dataset.gainfos
		})
	}

	render() {
		if (this.state.errorMessage) {
			return (
				<div className='SocialIcons'>
					<Error errorMessage={this.state.errorMessage} />
				</div>
			)
		}

		if (this.state.loading) {
			return (
				<div className='SocialIcons'>
					<Spinner />
				</div>
			)
		}

		return (
			<ul className={'SocialIcons'}>
				<li>
					<a href={this.state.copy.facebook} target='_blank' rel='noopener noreferrer' onClick={this.socialClickHandler}>
						<svg data-gainfos='Facebook' className='icon-facebook' x='0px' y='0px' viewBox='0 0 167.657 167.657'>
							<path d='M83.829,0.349C37.532,0.349,0,37.881,0,84.178c0,41.523,30.222,75.911,69.848,82.57v-65.081H49.626
								v-23.42h20.222V60.978c0-20.037,12.238-30.956,30.115-30.956c8.562,0,15.92,0.638,18.056,0.919v20.944l-12.399,0.006
								c-9.72,0-11.594,4.618-11.594,11.397v14.947h23.193l-3.025,23.42H94.026v65.653c41.476-5.048,73.631-40.312,73.631-83.154
								C167.657,37.881,130.125,0.349,83.829,0.349z'/>
						</svg>
					</a>
				</li>
				<li>
					<a href={this.state.copy.instagram} target='_blank' rel='noopener noreferrer' onClick={this.socialClickHandler}>
						<svg data-gainfos='Instagram' className='icon-instagram' x='0px' y='0px' viewBox='0 0 468.792 468.792'>
							<path d='M234.396,0C104.946,0,0,104.946,0,234.396s104.946,234.396,234.396,234.396
								s234.396-104.946,234.396-234.396C468.792,104.914,363.846,0,234.396,0z M380.881,370.329c0,5.816-4.736,10.552-10.615,10.552
								H98.462c-5.816,0-10.584-4.704-10.584-10.552V98.462c0-5.816,4.736-10.584,10.584-10.584h271.804
								c5.848,0,10.615,4.736,10.615,10.584C380.881,98.462,380.881,370.329,380.881,370.329z M175.789,234.396
								c0-32.355,26.252-58.607,58.607-58.607s58.607,26.252,58.607,58.607s-26.252,58.607-58.607,58.607
								S175.789,266.75,175.789,234.396z M293.003,117.182h58.607v58.607h-58.607V117.182z M319.636,219.744h31.973v131.834H117.214
								V219.744h32.005c-1.24,5.88-1.971,8.422-1.971,14.652c0,48.119,39.124,87.179,87.179,87.179
								c48.119,0,87.179-39.061,87.179-87.179C321.575,228.166,320.876,225.624,319.636,219.744z'/>
						</svg>
					</a>
				</li>
				<li>
					<a href={this.state.copy.tiktok} target='_blank' rel='noopener noreferrer' onClick={this.socialClickHandler}>
						<svg data-gainfos="Tiktok" className="icon-tiktok" x="0px" y="0px" viewBox="168 41 512 512">
							<path d="M424,41c-141.4,0-256,114.6-256,256s114.6,256,256,256s256-114.6,256-256S565.4,41,424,41z M552.4,236.9v34.7 c-16.3,0-32.2-3.2-47.2-9.5c-9.6-4.1-18.6-9.3-26.8-15.6l0.2,106.7c-0.1,24-9.6,46.6-26.8,63.6c-14,13.8-31.7,22.6-51,25.5 c-4.5,0.7-9.1,1-13.8,1c-20.6,0-40.1-6.7-56.1-19c-3-2.3-5.9-4.8-8.6-7.5c-18.6-18.4-28.3-43.4-26.6-69.7c1.2-20,9.2-39.1,22.6-54 c17.7-19.8,42.4-30.8,68.8-30.8c4.6,0,9.2,0.3,13.8,1v12.8v35.7c-4.3-1.4-8.9-2.2-13.6-2.2c-24.1,0-43.7,19.7-43.3,43.8 c0.2,15.5,8.7,29,21.1,36.4c5.9,3.5,12.6,5.7,19.8,6.1c5.6,0.3,11-0.5,16-2.1c17.2-5.7,29.7-21.9,29.7-41l0.1-71.4V150.8h47.7 c0,4.7,0.5,9.3,1.4,13.8c3.6,18.1,13.8,33.8,28,44.5c12.4,9.3,27.8,14.9,44.5,14.9c0,0,0.1,0,0.1,0L552.4,236.9L552.4,236.9z"></path>
						</svg>
					</a>
				</li>
			</ul>
		)
	}
}

export default SocialIcons