import React from 'react'
import NavigationItems from '../NavigationItems/NavigationItems'
import SocialIcons from '../SocialIcons/SocialIcons'
import './SideDrawer.css'
import Backdrop from '../../UI/Backdrop/Backdrop'
import Empty from '../../../hoc/Empty/Empty'

const sideDrawer = (props) => {
	let attachedClasses = ['SideDrawer', 'Close']
	if (props.open) {
		attachedClasses = ['SideDrawer', 'Open']
	}
	return (
		<Empty>
			<div className={attachedClasses.join(' ')}>
				<nav>
					<NavigationItems closeSidebar={props.closed}/>
				</nav>
				<SocialIcons />
			</div>
			<Backdrop show={props.open} clicked={props.closed}/>
		</Empty>
	)
}

export default sideDrawer