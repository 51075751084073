import React from 'react'

const input = ( props ) => {
	let inputFormGroup = null

	switch ( props.elementType ) {
		case ( 'input' ):
			inputFormGroup = (
				<div className='Input form-group'>
					<input
						className='InputElement'
						{...props.elementConfig}
						id={props.id}
						value={props.value}
						onChange={props.changed}
						required={props.required} />
					<label htmlFor={props.id} className='Label'>{props.label}</label>
				</div>
			)
			break
		case ( 'textarea' ):
			inputFormGroup = (
				<div className='Input form-group textarea'>
					<textarea
						className='InputElement'
						{...props.elementConfig}
						id={props.id}
						value={props.value}
						onChange={props.changed}
						required={props.required} />
					<label htmlFor={props.id} className='Label'>{props.label}</label>
				</div>
			)
			break
		case ( 'select' ):
			inputFormGroup = (
				<div className='Input form-group'>
					<select
						className='InputElement'
						id={props.id}
						value={props.value}
						onChange={props.changed}
						required={props.required}>
						{props.elementConfig.options.map(option => (
							<option key={option.value} value={option.value}>
								{option.displayValue}
							</option>
						))}
					</select>
					<label htmlFor={props.id} className='Label'>{props.label}</label>
				</div>
			)
			break
		case ( 'checkbox' ):
			inputFormGroup = (
				<div className='Input form-group checkbox'>
					<label htmlFor={props.id} className='Label'>
						<span dangerouslySetInnerHTML={{__html: props.label}}></span>
						<input
						className='InputElement'
						{...props.elementConfig}
						id={props.id}
						value={props.value}
						onChange={props.changed}
						required={props.required} />
						<span className='checkmark'></span>
					</label>
				</div>
			)
			break
		default:
			inputFormGroup = (
				<div className='Input form-group'>
					<input
					className='InputElement'
					{...props.elementConfig}
					id={props.id}
					value={props.value}
					onChange={props.changed}
					required={props.required} />
					<label htmlFor={props.id} className='Label'>{props.label}</label>
				</div>
			)
	}

	return inputFormGroup

}

export default input